let section_counter = document.querySelector('.stellenangeboten');
let counters = document.querySelectorAll('.stellenangeboten__title');

document.querySelector('.stellenangeboten__title').innerHTML = 0;
// Scroll Animation
let CounterObserver = new IntersectionObserver(
  (entries, observer) => {
    let [entry] = entries;
    if (!entry.isIntersecting) return;

    let speed = 200;
    counters.forEach((counter, index) => {
      function UpdateCounter() {
        const targetNumber = +counter.dataset.target;
        const initialNumber = +counter.innerText;
        const incPerCount = targetNumber / speed;
        if (initialNumber < targetNumber) {
          counter.innerText = Math.ceil(initialNumber + incPerCount);
          setTimeout(UpdateCounter, 10);
        }
        else {
          counter.innerText = targetNumber;
        }
      }
      UpdateCounter();
    });
    observer.unobserve(section_counter);
  },

);

CounterObserver.observe(section_counter);

