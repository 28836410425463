import $ from "jquery";

class CookieNotice {
    constructor() {
        if($('#cookienotice_box').length) {
            if(this.checkIfCanInitialise()) {
                this.initialise();
                if(!this.isSetCookie('cntb_set_'+$('#cookienotice_box').attr('data-configuration-uid'))) {
                    var initialiseOpen = true;
                    if(window.location.href.indexOf("impressum") > -1) {
                        initialiseOpen = false;
                    }
                    if(window.location.href.indexOf("datenschutz") > -1) {
                        initialiseOpen = false;
                    }
                    if($('body').hasClass('disable-cookienotice')) {
                        initialiseOpen = false;
                    }
                    if(initialiseOpen) {
                        this.open();
                    }
                }
            }
        }
    }

    checkIfCanInitialise() {
        if(/adidxbotc|Applebot\/|archive.org_bot|asterias\/|Baiduspider\/|bingbot\/|BingPreview\/|DuckDuckBot\/|FAST-WebCrawler\/|Feedspot|Feedspotbot\/|Google Page Speed Insights|Google PP|Google Search Console|Google Web Preview|Googlebot\/|Googlebot-Image\/|Googlebot-Mobile\/|Googlebot-News|Googlebot-Video\/|Google-SearchByImage|Google-Structured-Data-Testing-Tool|Chrome-Lighthouse|heritrix\/|iaskspider\/|Mediapartners-Google|msnbot\/|msnbot-media\/|msnbot-NewsBlogs\/|msnbot-UDiscovery\/|SEMrushBot|special_archiver\/|Y!J-ASR\/|Y!J-BRI\/|Y!J-BRJ\/YATS|Y!J-BRO\/YFSJ|Y!J-BRW\/|Y!J-BSC\/|Yahoo! Site Explorer Feed Validator|Yahoo! Slurp|YahooCacheSystem|Yahoo-MMCrawler\/|YahooSeeker\//i.test(navigator.userAgent)) {
            return false;
        }
        return true;
    }

    initialise() {
        if(!$('#cookienotice_box').hasClass('initialised')) {
            $('#cookienotice_box').addClass('initialised');
            $('#cookienotice_box div[data-key="required"] input').attr({checked: 'checked', readonly: 'readonly', disabled: 'disabled'})
            $('#cookienotice_box .cookienotice-cookie-more').click(function(){
                if($(this).parent().find('.cookienotice-cookie-items').css('display') == 'none') {
                    $(this).parent().find('.cookienotice-cookie-items').show();
                    $(this).hide();
                } else {
                    $(this).parent().find('.cookienotice-cookie-items').hide();
                }
            });
            var setCookieRights = this.getCookie('cntb_set_'+$('#cookienotice_box').attr('data-configuration-uid'));
            let _this = this;
            $.each(setCookieRights.split(','), function(i,val) {
                if(val != '') {
                    $('input[name="'+val+'"]').prop('checked', true);
                    _this.initialiseTracking(val);
                }
            });
            $('#cookienotice_box input[data-key]').change(function() {
                if($(this).is(':checked')) {
                    $('#cookienotice_box input[data-parent-key="'+$(this).attr('data-key')+'"]').prop('checked', true);
                } else {
                    $('#cookienotice_box input[data-parent-key="'+$(this).attr('data-key')+'"]').prop('checked', false);
                }
            });
            $('#cookienotice_box input[data-parent-key]').change(function() {
                let _select_parent = true;
                $('#cookienotice_box input[data-parent-key="'+$(this).attr('data-parent-key')+'"]').each(function(){
                    if(!$(this).is(':checked')) {
                        _select_parent = false;
                    }
                });
                if(_select_parent) {
                    $('#cookienotice_box input[data-key="'+$(this).attr('data-parent-key')+'"]').prop('checked', true);
                } else {
                    $('#cookienotice_box input[data-key="'+$(this).attr('data-parent-key')+'"]').prop('checked', false);
                }
            });

            $('#cookienotice_box_accept_all').click((e) => {
                e.preventDefault();
                $('#cookienotice_box input[type="checkbox"]').prop('checked', true);
                this.saveAndClose();

                if ($('.cookienotice_placeholder_googlemaps')[0]) {
                    if (!$('.cookienotice_placeholder_googlemaps').hasClass('desactivated')) {
                        location.reload();
                    }
                }

                if ($('.cookienotice_placeholder_userlike')[0]) {
                    if (!$('.cookienotice_placeholder_userlike').hasClass('desactivated')) {
                        location.reload();
                    }
                }
            });
            $('#cookienotice_box_close').click((e) => {
                e.preventDefault();
                this.saveAndClose();

                $('#cookienotice_box input[type="checkbox"]:not(:checked)').each(function() {
                    console.log($(this).attr('name'));
                    if($(this).attr('name') === 'userlike') {
                        document.cookie.split(";").forEach(function(c) {
                            console.log(c);
                            if (c.includes('uslk')) {
                                document.cookie = c + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                            }
                        });
                    } else if($(this).attr('name') === 'googleTagmanager') {
                        document.cookie.split(";").forEach(function(c) {
                            if (c.includes('_ga') || c.includes('_gat') || c.includes('_gid')) {
                                document.cookie = c + ' Path=/; Domain='+ window.location.hostname.substring(window.location.hostname.indexOf('.')+1) +'; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                                location.reload();
                            }
                        });
                    }
                })

                if ($('.cookienotice_placeholder_googlemaps')[0]) {
                    if (!$('.cookienotice_placeholder_googlemaps').hasClass('desactivated')) {
                        location.reload();
                    }
                }

                if ($('.cookienotice_placeholder_userlike')[0]) {
                    if (!$('.cookienotice_placeholder_userlike').hasClass('desactivated')) {
                        location.reload();
                    }
                }
            });
            $('#cookienotice_box_decline_all').click((e) => {
                e.preventDefault();
                $('#cookienotice_box input[type="checkbox"]').prop('checked', false);
                document.cookie.split(";").forEach(function(c) {
                    document.cookie = c + ' Path=/; Domain='+ window.location.hostname.substring(window.location.hostname.indexOf('.')+1) +'; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    document.cookie = c + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                });
                this.saveAndClose();
              
                location.reload();
            });
            $('.cookieNotice-open').click((e) => {
                e.preventDefault();
                $('#cookienotice_box .cookienotice-cookie-more').show();
                $('#cookienotice_box .cookienotice-cookie-items').hide();
                this.open();
                var tmpOpenCookie = $(e.currentTarget).attr('data-cookie-name');
                if($('#cookienotice_box input[name="'+tmpOpenCookie+'"]').length) {
                    if($('#cookienotice_box input[name="'+tmpOpenCookie+'"]').attr('data-parent-key')) {
                        $('#cookienotice_box input[name="'+tmpOpenCookie+'"]').closest('.cookienotice-cookie-items-item').addClass('cookiehighlight');
                        if($('#cookienotice_box input[name="'+tmpOpenCookie+'"]').attr('data-parent-key')) {
                            $('#cookienotice_box .cookienotice-cookie[data-key="'+$('#cookienotice_box input[name="'+tmpOpenCookie+'"]').attr('data-parent-key')+'"]').find('.cookienotice-cookie-more')[0].click();
                        }
                    } else {
                        $('#cookienotice_box input[name="'+tmpOpenCookie+'"]').closest('.cookienotice-cookie').addClass('cookiehighlight');
                        $('#cookienotice_box input[name="'+tmpOpenCookie+'"]').closest('.cookienotice-cookie').find('.cookienotice-cookie-more')[0].click();
                    }

                }
            });
            $('.dataCookienoticeAllowMessage').show();
        }
    }

    open() {
        $('#cookienotice_box .cookiehighlight').removeClass('cookiehighlight');
        $('body').addClass('show-cookienotice');
    }

    saveAndClose() {
        let _cookies = '';
        let _this = this;
        $('#cookienotice_box input[type="checkbox"]').each(function(){
            if($(this).is(':checked')) {
                if(_cookies == '') {
                    _cookies += ',';
                }
                _cookies += $(this).attr('name')+',';
                _this.initialiseTracking($(this).attr('name'));
            }
        });

        _cookies = _cookies.substring(1);
        this.setCookie('cntb_set_'+$('#cookienotice_box').attr('data-configuration-uid'),_cookies);

        $('body').removeClass('show-cookienotice');
    }

    isSetCookie(name) {
        if(decodeURIComponent(document.cookie).indexOf(name) === -1) {
            return false;
        }
        return true;
    }

    setCookie(cname,value) {
        var d = new Date();
        d.setTime(d.getTime() + (365*24*60*60*1000));
        document.cookie = cname+"="+value+";expires=" + d.toUTCString() + ";path=/";
    }

    getCookie(cname) {
        const ca = document.cookie.split(";"),
        name = `${cname}=`;
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];

            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }

            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    initialiseTracking(name) {
        $('.dataCookienoticeAllowMessage-'+name).remove();
        if($('[data-cookienotice-'+name+'-first]').length) {
            $('[data-cookienotice-'+name+'-first]').each(function(){
                eval($(this).attr('data-cookienotice-'+name+'-first'));
                $(this).removeAttr('data-cookienotice-'+name+'-first');
            });
         }
         if($('[data-cookienotice-'+name+']').length) {
            $('[data-cookienotice-'+name+']').each(function(){
                eval($(this).attr('data-cookienotice-'+name));
                $(this).removeAttr('data-cookienotice-'+name);
            });
         }
         if(name == 'youtube') {
            $('.cookienotice_placeholder_youtubeiframe.desactivated').each(function(){
                $(this).removeClass('desactivated');
                $(this).find('iframe').attr('src',$(this).find('iframe').attr('src-data'));
            });
         }
         if(name == 'vimeo') {
            $('.cookienotice_placeholder_vimeoiframe.desactivated').each(function(){
                $(this).removeClass('desactivated');
                $(this).find('iframe').attr('src',$(this).find('iframe').attr('src-data'));
            });
         }
         if(name == 'googleMaps') {
            $('.cookienotice_placeholder_googlemaps.desactivated').each(function(){
                $(this).removeClass('desactivated');
                $(this).find('iframe').attr('src',$(this).find('iframe').attr('src-data'));
            });
         }
    }
}

$(document).ready(function() {
    let cookieNoticeModul = new CookieNotice();

    $('.cookie-icon-box').on('click',function(){
        cookieNoticeModul.open();
    });

    $('.cookienotice_placeholder_youtubeiframe.desactivated p').on('click', function() {
        $('#cookienotice_box').addClass('activate');
    });
    
    $('.cookienotice_placeholder_googlemaps.desactivated p').on('click', function() {
        $('#cookienotice_box').addClass('activate');
    });

    $('#cookienotice_box .cookienotice-buttons .btn').on('click', function()  {
        if($('#cookienotice_box').hasClass('activate')) {
            $('#cookienotice_box').removeClass('activate');
        }
    });

    if ($('.cookienotice_placeholder_googlemaps')[0]) {
        if ($('.cookienotice_placeholder_googlemaps').hasClass('desactivated')) {
            $('.cookienotice_placeholder_googlemaps .gmap').addClass('no-map').removeClass('gmap');
        }
    }
});