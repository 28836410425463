
let headerBtn = document.querySelector('.header__btn');
let menu = document.querySelector('.menu');


headerBtn.addEventListener('click', () => {
    menu.classList.toggle('menu--active');
    headerBtn.classList.toggle('header__btn--active');
    document.querySelectorAll('.header__item').forEach(element => {
        element.classList.toggle('header__item--active');
    });

    document.querySelector('body').classList.toggle('body--hidden');
    
});

document.querySelectorAll('.menu__link').forEach(link => {
    link.addEventListener('click', () => {
        if(!menu.classList.contains('header__btn--active')) {
            menu.classList.remove('menu--active');

            document.querySelectorAll('.header__item').forEach(element => {
                element.classList.toggle('header__item--active');
            });

            document.querySelector('body').classList.remove('body--hidden');
        }
    });
});



window.addEventListener('scroll', () => {
    let headerHeight = document.querySelector('.header').clientHeight;
    if(window.scrollY >= 150) {
        document.querySelector('.header').classList.add('header--active');
        // document.querySelector('.header').nextElementSibling.style.transition = 'all 0.3s linear';
        document.querySelector('.header').nextElementSibling.style.paddingTop = headerHeight + 'px';
    }
    else {
        document.querySelector('.header').classList.remove('header--active');
        document.querySelector('.header').nextElementSibling.style.paddingTop = '0';
    }

});