


if(document.querySelectorAll('.accordion__content').length > 0) {
    let content = document.querySelectorAll('.accordion__content');
    content[0].style.maxHeight = '1000px';
    let btns = document.querySelectorAll('.accordion__btn');
    btns[0].classList.add('accordion__btn--close');
    document.querySelectorAll('.accordion__btn').forEach((item) => {
        item.addEventListener('click', () => {

            item.classList.toggle('accordion__btn--close');
    
            let content = item.nextElementSibling;

            let str = content.style.maxHeight.split('px').join('');

            let num = Number(str);

                if(num > 0) {
                    content.style.maxHeight = null;
                } else {
                    content.style.maxHeight = content.scrollHeight + 'px';
                }  

        });
    });
}