

import Swiper from 'swiper/bundle';



if (window.matchMedia("(max-width: 992px)").matches) {
    const swiper = new Swiper('.tabs-swiper', {
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        }
});


} else {
    let tabContent = document.getElementsByClassName('tabs__content');
    let tab = document.querySelectorAll('.tabs__tab');
    tab[0]?.classList.add('tabs__tab--color');
    hideTabsContent(1);


    document.querySelectorAll('.tabs__tab').forEach(btn => {
        btn.addEventListener('click', (event) => {
            tab.forEach(el => {
                el.classList.remove('tabs__tab--color');
            });
    
            for (let i = 0; i < tab.length; i++) {
                if (btn == tab[i]) {
                    showTabsContent(i);
                    break;
                }
            }
            btn.classList.add('tabs__tab--color');
        });
    });
    
    
    function hideTabsContent(a) {
        for (let i = a; i < tabContent.length; i++) {
            tabContent[i].classList.remove('show');
            tabContent[i].classList.add('hide');
        }
    }
    
    function showTabsContent(b) {
        if (tabContent[b].classList.contains('hide')) {
            hideTabsContent(0);
            tabContent[b].classList.remove('hide');
            tabContent[b].classList.add('show');
        }
    }
    
}


